<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top :white="$store.state.model.black" ref="top"></Top>

    <div class="history_body">
      <!-- 左侧 -->
      <Leftside></Leftside>
      <!-- 右侧主题 -->
      <transition name="RightToleft">
        <div class="his_r_box" v-show="enter">
          <div class="history_body_right">
            <div class="his_r_title">
              <span>充值记录</span>
            </div>
            <!-- 主体 -->
            <div class="his_r_body">
              <!-- 没有数据内容会显示没有内容 -->
              <None v-if="!recharge_record_list || recharge_record_list == null"></None>
              <div class="rech">
                <div class="rech_list" v-for="(item, index) in recharge_record_list" :key="index">
                  <span>{{ YS.toTime(item.pay_time) }}</span>
                  <span>
                    {{ item.description }}
                    <span>
                      {{ item.amount }}
                      <span>{{ item.currency }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="pagination" v-show="fy.pageEnter && fy.pagenum > 1">
            <div class="pagebody">
              <div class="page_left" @click="YS.pagebutton('left', fy, rechargeRecord)">
                <img src="../assets/img/left.png" alt="上一页" />
              </div>
              <div class="page_cent">
                <div
                  class="page_num"
                  v-for="(item, index) in fy.pageList"
                  :key="index"
                  @click="YS.clickPage(item, fy, rechargeRecord)"
                  :class="{ 'page_num-act': fy.pages.page == item }"
                >
                  <a>{{ item }}</a>
                </div>
              </div>

              <div class="page_right" @click="YS.pagebutton('right', fy, rechargeRecord)">
                <img src="../assets/img/right.png" alt="下一页" />
              </div>
              <div class="page_total">
                <input
                  class="fenye_input"
                  v-model="fy.jump"
                  @focus="YS.getInputFocus($event)"
                  @keyup.enter="YS.clickPage(fy.jump, fy, rechargeRecord)"
                />
              </div>
              <div class="page_total">
                <span>共{{ fy.pagenum }}页</span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
let that;
let fetch;
import Top from '../components/Top'; //导航top
import Leftside from '../components/Leftside'; //左侧主题
import None from '../components/None'; //无内容
export default {
  components: {
    Top,
    Leftside,
    None,
  },
  name: 'Recharge',
  data() {
    return {
      jsonList: null,
      viptime: null,
      recharge_record_list: null,
      enter: false,
      havedata: false, //控制 显示没有任何内容 组件展示
      //分页用
      fy: {
        pageEnter: false,
        pagenum: null,
        pagetotal: 20,
        pages: {
          page: 1,
          pagesize: 9,
        },
        mobanpage: [1, 2, 3, 4, 5, 6, 7, 8],
        pageList: [1, 2, 3, 4, 5, '...', 11, 12],
      },
    };
  },
  created() {
    that = this;
    fetch = this.api.user;

    that.YS.toMobile('/recharge');
  },

  beforeMount() {
    that.rechargeRecord();
  },
  async mounted() {
    try {
      //取判断标准老的vip时间，在这之前获取用户信息都会记录老vip时间
      if (!localStorage.getItem('vtime')) {
        if (localStorage.getItem('user')) {
          var vipOldtime = JSON.parse(localStorage.getItem('user')).vip_expired_time;
          localStorage.setItem('vtime', JSON.stringify(vipOldtime));
        }
      }
      let data = await that.api.user.api_info({});
      var tem_local = JSON.parse(localStorage.getItem('user'));
      tem_local.vip_expired_time = data.user.vip_expired_time;
      localStorage.setItem('user', JSON.stringify(tem_local));
      that.$store.commit('changelogin');
      that.$store.commit('getisvipCode');
    } catch (error) {
      console.log(error.message);
    }
    // decodeURIComponent(window.location.href);
    // debugger;

    var url = decodeURIComponent(window.location.search);
    if (url) {
      that.jsonList = {};

      if (url.indexOf('?') > -1) {
        var str = url.slice(url.indexOf('?') + 1);
        var strs = str.split('&');
        for (var i = 0; i < strs.length; i++) {
          that.jsonList[strs[i].split('=')[0]] = strs[i].split('=')[1]; //如果出现乱码的话，可以用decodeURI()进行解码
        }
      }
      // jsonList;
      // console.log(getRequest());
      if (that.jsonList) {
        if (that.jsonList.trade_status == 'success') {
          that.$refs.top.pay_test = '支付成功！';
          that.$refs.top.getvipres();
          that.setOrder();
        } else if (that.jsonList.go == 'pay') {
          that.$refs.top.pay_test = '支付成功！';
          that.$refs.top.getvipres();
          that.setOrder();
        } else if (that.$route.query.go == 'paySecond') {
          that.$refs.top.pay_test = '正在重复获取订单状态';
          that.$refs.top.getvipres();
        }
      }
    }
    // if (that.$route.query.go) {
    //   if (that.$route.query.go == 'pay') {
    //     that.$refs.top.pay_test = '支付成功！';
    //     that.$refs.top.getvipres();
    //     that.setOrder();
    //   } else if (that.$route.query.go == 'paySecond') {
    //     that.$refs.top.pay_test = '正在重复获取订单状态';
    //     that.$refs.top.getvipres();
    //   }
    // }

    that.enter = true;
    that.YS.closeallLogin(); //关闭所有弹窗
    that.YS.closeallWin(); //关闭所有弹窗
    that.YS.actlist(that.$store.state.leftListcss, 2, 'history_lc_list_act'); //添加默认选中样式
    that.YS.iconAct(that.$store.state.leftComponent, 2); //添加默认选中样式
  },
  methods: {
    //写入进入支付成功的时间
    setOrder() {
      if (!that.jsonList) return;

      // if (that.$route.query.time) {
      if (that.jsonList.pay_time) {
        //获取世界时间戳
        let time = Math.round(
          new Date(decodeURIComponent(that.jsonList.pay_time).replace(/\+/g, ' ')).getTime() / 1000,
        ).toString();
        //获取用户地区与世界的时间差
        let timeCha = Math.abs(new Date().getTimezoneOffset());
        localStorage.setItem('pay_ok', parseInt(time) + parseInt(timeCha) * 60);
      } else if (that.jsonList.time) {
        localStorage.setItem('pay_ok', that.jsonList.time);
      } else {
        return;
      }
    },
    //api获取充值记录
    rechargeRecord() {
      fetch.api_rechargeRecord(that.fy.pages).then((data) => {
        if (data && data.recharge_record_list) {
          that.recharge_record_list = data.recharge_record_list;
          that.fy.pagetotal = data.recharge_record_total;
          that.YS.jilu(that.fy);
        }
      });
    },
  },
};
</script>
<style lang="scss" scope>
// .top-nav {
//   background: white;
// }
// .tag-input {
//   background: #eeeeee;
//   // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
// }

// .tag-span,
// .right-content span {
//   color: rgba(51, 51, 51, 1);
// }
// .bubble {
//   background: #91d6ff;
// }
</style>
